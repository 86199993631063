import React, { Component } from 'react';
import { Text, Image, Block } from 'components/atoms';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'gatsby';

//ICONS
import Back from '@material-ui/icons/KeyboardArrowLeft';

// HOC
import withTheme from 'hocs/withTheme';
import newWithProfile from 'hocs/newWithProfile';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import {
  getAnnouncements,
  getNewNotification
} from 'modules/notification/actions';
import {
  selectData,
  selectAnnouncementsStatus
} from 'modules/notification/selectors';

const StyledBlock = styled('div')({
  '.imagestyle': {
    maxHeight: '300px',
    objectFit: 'cover'
  },
  '.title': {
    fontSize: 35,
    fontWeight: 'bold',
    // fontFamily:'thai-sans-neue',
    marginLeft: '20px' //t r b l
  },
  '.subtitle': {
    fontSize: 25,
    // fontFamily:'thai-sans-neue',
    marginLeft: '20px',
    marginTop: '-12px'
  },
  '.detail': {
    fontSize: 27,
    // fontFamily:'thai-sans-neue',
    marginLeft: '20px'
  },
  '.line': {
    opacity: 0.33,
    border: 'solid 0.7px #b8b8b8'
  },
  '.backIcon': {
    position: 'absolute',
    color: 'white',
    top: 13,
    left: 6
  }
});
class NotiDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcement: null
    };
  }
  componentDidMount() {
    const { announcements } = this.props;
    let url_string = window.location.href;
    let url = new URL(url_string);
    const notificationId = url.searchParams.get('notificationId');
    if (announcements.length === 0) {
      this.props.getNewNotification(notificationId).then(data => {
        this.setState({
          announcement: data.value
        });
      });
    } else if (announcements.length !== 0) {
      this.setState({
        announcement: announcements.find(
          announcement => announcement.id === notificationId
        )
      });
    } else {
      this.props.getAnnouncements().then(() => {
        this.setState({
          announcement: announcements.find(
            announcement => announcement.id === notificationId
          )
        });
      });
    }
  }

  render() {
    const { announcement } = this.state;
    return (
      <StyledBlock>
        {announcement && (
          <Block>
            <Link to="/MyAccount">
              <IconButton className={'backIcon'}>
                <Back style={{ fontSize: 40 }} />
              </IconButton>
            </Link>
            <Block
              display={'flex'}
              justifyContent={'center'}
              className={'imagestyle'}>
              <Image src={announcement.image} style={{ width: '100%' }} />
            </Block>

            <Block>
              <Text className={'title'}>{announcement.title}</Text>
              <Text className={'subtitle'}>{announcement.subtitle}</Text>
              <hr className={'line'} />
              <Text className={'detail'}>{announcement.subtitle}</Text>
            </Block>
          </Block>
        )}
      </StyledBlock>
    );
  }
}

export default compose(
  connect(
    state => ({
      announcements: selectData(state),
      getAnnouncementsStatus: selectAnnouncementsStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getAnnouncements,
          getNewNotification
        },
        dispatch
      )
  ),
  newWithProfile
)(withTheme()(NotiDetailPage));
